import React from 'react'
import {Link} from 'react-router-dom'
import banner1 from '../../assets/images/new/banner_01.png'
import banner2 from '../../assets/images/new/banner_02.png'
import banner3 from '../../assets/images/new/banner_03.png'
import banner4 from '../../assets/images/new/banner_04.png'

function Bannerhome() {
  return (
    <>
      <section className="banner_section">
        <div className="container">
          <div className="banner_text">
            <div
              className="ban_inner_text"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span>Potente, fácil de usar, y el más rápido software de gestión para tu ISP</span>
              <h1>
                Lleva tu empresa al siguiente nivel
              </h1>
              <p>Te ofrecemos herramientas para maximizar la productividad de tu empresa </p>
            </div>
            <div className="btn_group">
              <Link to="/signup"
                    className="btn btn_main"
                    data-aos="fade-right"
                    data-aos-duration={1500}
              >
                INICIA 30 DÍAS DE PRUEBA GRATUITA <i className="icofont-arrow-right"/>
              </Link>
            </div>
          </div>
          <div className="banner_images" data-aos="fade-up" data-aos-duration={1500}>
            <img src={banner1} alt="image"/>
            <div className="sub_images">
              <img
                className="moving_animation"
                src={banner2} alt="image"
              />
              <img
                className="moving_animation"
                src={banner3} alt="image"
              />
              <img
                className="moving_animation"
                src={banner4} alt="image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Bannerhome
