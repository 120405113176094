import React from 'react';

function AdvanceFeature() {
  const featureBlocks = [
    {
      icon: '/assets/images/new/Secure-data.svg',
      title: 'Secure data',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      icon: '/assets/images/new/Fully-functional.svg',
      title: 'Fully functional',
      description: 'Simply dummy text of the printing and typesetting industry.',
    },
    {
      icon: '/assets/images/new/Live-chat.svg',
      title: 'Live chat',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      icon: '/assets/images/new/24-7-Support.svg',
      title: '24-7 Support',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
  ];

  return (
    <>
      <section className="row_am advance_feature_section" id="getstarted">
        <div className="container">
          <div className="advance_feature_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
            <div className="adf_shapes">
              <span><img src="/assets/images/new/plus.svg" alt="image" /></span>
              <span><img src="/assets/images/new/polygon.svg" alt="image" /></span>
              <span><img src="/assets/images/new/round.svg" alt="image" /></span>
            </div>
            <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
              <h2>Advaced features</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="row">
              {featureBlocks.map((featureBlock, index) => (
                <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1500" key={index}>
                  <div className="feature_block">
                    <div className="icon">
                      <img src={featureBlock.icon} alt="image" />
                    </div>
                    <div className="text_info">
                      <h3>{featureBlock.title}</h3>
                      <p>{featureBlock.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdvanceFeature;
