import React from 'react'
import {Link} from 'react-router-dom'
import {useState} from 'react'

function Pricing() {
  const [billingType, setBillingType] = useState(1)

  const [basePrices, setBasePrices] = useState([
    {label: 'menos de 100 usuarios', price: 150000},
    {label: 'hasta 300 usuarios', price: 250000},
    {label: 'hasta 600 usuarios', price: 250000},
    {label: 'hasta 1000 usuarios', price: 250000},
    {label: 'hasta 3000 usuarios', price: 250000},
    {label: 'hasta 5000 usuarios', price: 250000},
    {label: 'mas de 5000 usuarios', price: 250000},
  ])
  const [prices, setPrices] = useState([
    {type: 'Mensual', id: 1, discount: 0},

  ])
  return (
    <>
      <section className="row_am pricing_section" id="pricing" data-aos="fade-in" data-aos-duration="1000">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
            <h2>Queremos verte crecer, paga lo justo para el tamaño de tu empresa</h2>
            <p>obtén descuentos por pagos trimestrales semestrales y anuales.</p>
          </div>


          <div className="pricing_pannel monthly_plan active" data-aos="fade-up" data-aos-duration="1500">
            <div className={"form-group"}>
              <select className={'form-control'}>
                {basePrices.map(item => {
                  return <option value={item.price}>{item.label}</option>
                })}
              </select>
            </div>
            <div className="row">

              <div className="col-md-3">
                <div className="pricing_block highlited_block">
                  <div className="icon">
                    <img src="/assets/images/new/unlimited.png" alt="image"/>
                    <div className="dot_block">
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                    </div>
                  </div>
                  <div className="pkg_name">
                    <h3>Nova</h3>
                    <span>La mas completa solución para tu empresa</span>
                  </div>
                  <span className="price">${99}<span>/Mes</span></span>
                  <ul className="benifits">
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>30 días gratis</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Administración de clientes</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Facturación electrónica</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chat de whatsapp/telegram integrado</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chatbot de whatsapp/telegram</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Calendario/Agenda</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Gestión documental</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Rastreo GPS vehiculos</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-search"></i>Ver todas...</p>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pricing_block highlited_block">
                  <div className="icon">
                    <img src="/assets/images/new/unlimited.png" alt="image"/>
                    <div className="dot_block">
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                    </div>
                  </div>
                  <div className="pkg_name">
                    <h3>Nova</h3>
                    <span>La mas completa solución para tu empresa</span>
                  </div>
                  <span className="price">${99}<span>/Mes</span></span>
                  <ul className="benifits">
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>30 días gratis</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Administración de clientes</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Facturación electrónica</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chat de whatsapp/telegram integrado</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chatbot de whatsapp/telegram</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Calendario/Agenda</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Gestión documental</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Rastreo GPS vehiculos</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-search"></i>Ver todas...</p>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pricing_block highlited_block">
                  <div className="icon">
                    <img src="/assets/images/new/unlimited.png" alt="image"/>
                    <div className="dot_block">
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                    </div>
                  </div>
                  <div className="pkg_name">
                    <h3>Nova</h3>
                    <span>La mas completa solución para tu empresa</span>
                  </div>
                  <span className="price">${99}<span>/Mes</span></span>
                  <ul className="benifits">
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>30 días gratis</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Administración de clientes</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Facturación electrónica</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chat de whatsapp/telegram integrado</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chatbot de whatsapp/telegram</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Calendario/Agenda</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Gestión documental</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Rastreo GPS vehiculos</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-search"></i>Ver todas...</p>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pricing_block highlited_block">
                  <div className="icon">
                    <img src="/assets/images/new/unlimited.png" alt="image"/>
                    <div className="dot_block">
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                      <span className="dot_anim"></span>
                    </div>
                  </div>
                  <div className="pkg_name">
                    <h3>Nova</h3>
                    <span>La mas completa solución para tu empresa</span>
                  </div>
                  <span className="price">${99}<span>/Mes</span></span>
                  <ul className="benifits">
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>30 días gratis</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Administración de clientes</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Facturación electrónica</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chat de whatsapp/telegram integrado</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Chatbot de whatsapp/telegram</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Calendario/Agenda</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Gestión documental</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-check-circled"></i>Rastreo GPS vehiculos</p>
                    </li>
                    <li className="include">
                      <p><i className="icofont-search"></i>Ver todas...</p>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>
                </div>
              </div>


            </div>

          </div>
        </div>

      </section>
    </>
  )
}

export default Pricing
