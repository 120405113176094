import React from 'react'

function Map() {
    return (
        <>
            <section className="row_am map_section">
                <div className="container">
                    <div className="map_inner">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31802.608713926795!2d-75.66047796645253!3d4.884951325635098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3881809c7f5a9f%3A0x2849061d9ddbf2b6!2sSanta%20Rosa%20de%20Cabal%2C%20Risaralda!5e0!3m2!1ses!2sco!4v1694115072925!5m2!1ses!2sco"
                            style={{ border: "0", width: "100%", height: "510px" }}
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Map
