import React from 'react'

function SolutionPage() {
  return (
    <>
       <section className="row_am solution_page_section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="solution_image" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <img src="/assets/images/new/solution.png" alt="image"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="solution_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <div className="section_title">
                <h2>Providing IT solution that diverse business verticals</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and types
                  etting industry lorem Ipsum has been the indu has been the industrys standard dummy text ever since
                  the when an unknown printer took a galley of type and.</p>
              </div>
              <ul>
                <li className="ft_1" data-aos="fade-up" data-aos-duration="2000">
                  <h3>Creative & responsive design</h3>
                  <p>Simply dummy text of the printing and typesetting
                    inustry lorem Ipsum has Lorem dollar summit.</p>
                </li>
                <li className="ft_2" data-aos="fade-up" data-aos-duration="2000">
                  <h3>Pixel perfect</h3>
                  <p>Simply dummy text of the printing and typesetting
                    inustry lorem Ipsum has Lorem dollar summit.</p>
                </li>
                <li className="ft_3" data-aos="fade-up" data-aos-duration="2000">
                  <h3>24/7 live support</h3>
                  <p>Simply dummy text of the printing and typesetting
                    inustry lorem Ipsum has Lorem dollar summit.</p>
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SolutionPage
