import React from 'react'
import { Link } from 'react-router-dom'

import communication1 from "../../assets/images/new/communication-data-01.png"
import communication2 from "../../assets/images/new/communication-data-02.png"
import communication3 from "../../assets/images/new/communication-data-03.png"

function Communication() {
  return (
    <>
      <section className="row_am communication_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="communication_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <span className="icon"><img src="/assets/images/new/comunication.svg" alt="image" /></span>
                <div className="section_title">
                  <h2>Comunicate más agilmente con tus clientes</h2>
                  <p>integramos Whatsapp, Telegram, SMS, Email, Instagram y Facebook. Mantén la comunicacion con tus clientes
                  en un mismo sitio, y no te pierdas nunca más un mensaje</p>
                </div>
                <ul>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>Mensajes masivos</h3>
                    <p>Segmenta tus clientes, y envía mensajes masivos por los diferentes canales de comunicación</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>Un Inbox, muchos canales</h3>
                    <p>Simplifica las comunicaciones en un solo inbox, sin importar de donde proviene la comunicación.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="communication_image" data-aos="fade-in" data-aos-duration="1000">
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className="moving_animation"
                  src={communication1} alt="image" />
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className="moving_animation"
                  src={communication2} alt="image" />
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className=""
                  src={communication3} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Communication
