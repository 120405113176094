import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import logo from '../../assets/images/logos/png/logo-poli-inverted-small.png'
import Axios from "axios"

function SignUp() {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(false)
  const [domain, setDomain] = useState()

  const params = useParams()
  const axios = Axios.create({
    baseURL: 'https://cluster.novaisp.co/api/v1/public/company'
  })

  const validate = async () => {
    setLoading(true)
    try {
      const res = await axios.post('/validateEmail', params)
      const {data} = res
      setResponse(data)
      setDomain(data.domain)
    } catch (err) {
      const {data} = err.response
      setResponse(data)
    }

    setLoading(false)
  }

  useEffect(() => {
    validate()
  }, []);
  return (
    <>
      <section className="signup_section">

        <div className="top_part">
          <Link to="/" className="back_btn"><i className="icofont-arrow-left"></i> Volver al inicio </Link>
        </div>

        <div className="profile_block">

          <div className="profile_side">
            <div className="top_side">
              <Link to="#"><img src={logo} alt="image"/></Link>
              <p></p>
              <p>Nova gestión total, la herramienta más completa y potente para la gestión de tu ISP</p>

            </div>
          </div>

          <div className="signup_form">
            <div className="section_title">
              <h2>Descubre las herramientas que Nova tiene para ti</h2>
              {response && response.result === 'success' ?
                <>
                  <h1 className={'text-success pt-4'}>Tu correo electrónico se ha validado con éxito</h1>
                  <p className={'pt-5'}>En breve, un ejecutivo de ventas se pondrá en contacto para finalizar la
                    instalación</p>
                  <Link to="/"
                        className="btn btn_main"
                        data-aos="fade-right"
                        data-aos-duration={1500}
                  >
                    VOLVER AL INICIO <i className="icofont-arrow-left"/>
                  </Link>
                  <p></p>
                  {domain ? <Link to={`https://${domain}`}
                                  className="btn btn_main"
                                  data-aos="fade-right"
                                  data-aos-duration={1500}
                  >
                    IR A MI PÁGINA <i className="icofont-arrow-right"/>
                  </Link> : null}

                </> : null
              }

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignUp
