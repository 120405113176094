import React from 'react'
import { Link } from 'react-router-dom'

const contactItems = [
  {
    icon: '/assets/images/new/mail.png',
    title: '¿Te gustaría conocer más?',
    text: 'Agenda una cita con nuestro equipo de ventas',
    link: 'mailto:ventas@novaisp.co',
    buttonText: 'ventas@novaisp.co'
  },
  {
    icon: '/assets/images/new/location.png',
    title: 'Visita nuestras instalaciones',
    text: 'Visítanos en nuestras instalaciones',
    link: '#',
    buttonText: 'Cra 15 # 15-13, Santa Rosa de Cabal - Colombia'
  },
  {
    icon: '/assets/images/new/phone.png',
    title: 'Llamanos',
    text: 'Contacta nuestro equipo de ventas',
    link: 'tel:+57-320-5005001',
    buttonText: '+57 320 5005001'
  }
]

function ContactList() {
  return (
    <>
      <section className="row_am contact_list_section">
        <div className="container">
          <div className="contact_list_inner" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
            {contactItems.map(item => (
              <div className="c_list_card" key={item.title}>
                <div className="icons">
                  <img src={item.icon} alt="image" />
                  <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                  </div>
                </div>
                <div className="inner_text">
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  <Link to={item.link} className="text_btn">{item.buttonText}</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactList
