import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../components/Home/Main'
import Footer from '../components/Footer/Main'
import AboutUs from '../components/AboutUs/Main'
import Testimonial from '../components/Testimonial/Main'
import Contact from '../components/Contact/Main'
import ServiceDetail from '../components/ServiceDetail/Main'
import ServiceList1 from '../components/ServiceList1/Main'
import ServiceList2 from '../components/ServiceList2/Main'
import BlogList from '../components/BlogList/Main'
import BlogDetails from '../components/BlogDetails/Main'
import PagesPricing from '../components/PagesPricing/Main'
import SignIn from '../components/SignIn/Main'
import SignUp from '../components/SignUp/Main'
import ValidateSignUp from "../components/SignUp/Validate";
import Error from '../components/MainError404/Error'
import FAQ from '../components/FAQ/Main'
import SignForm from '../components/SignForm/Main'

function Index() {

  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]); const [FooterShow, setfooterShow] = useState(false)
  useEffect(() => {
    if (path === "/signin" || path === "/signup" || path === "/error") {
      setfooterShow(true)
    } else {
      setfooterShow(false)
    }
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <div className="page_wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/servicelist1" element={<ServiceList1 />} />
          <Route path="/servicelist2" element={<ServiceList2 />} />
          <Route path="/servicedetail" element={<ServiceDetail />} />
          <Route path="/bloglist" element={<BlogList />} />
          <Route path="/blogdetails" element={<BlogDetails />} />
          <Route path="/pricing" element={<PagesPricing />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup/validate/:token" element={<ValidateSignUp />} />
          <Route path="/signForm" element={<SignForm />} />
          <Route path="/error" element={<Error />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
        {!FooterShow && <Footer />}
      </div>

    </>
  )
}

export default Index