import React from 'react'
import analize1 from "../../assets/images/new/analyze-data-01.png"
import analize2 from "../../assets/images/new/analyze-data-02.png"
import analize3 from "../../assets/images/new/analyze-data-03.png"

function Analyze() {
  return (
    <>
      <section className="row_am analyze_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="analyze_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <span className="icon"><img src="/assets/images/new/Analyze_Icon.svg" alt="image"/></span>
                <div className="section_title">
                  <h2>Analiza tus datos con nuestras potentes herramientas</h2>
                  <p>Tomar decisiones para el crecimiento de tu empresa
                    es más efectivo si estás al tanto de las variables que componen tu operación.
                    Las causas más frecuentes de soporte, por que se retiran tus clientes, tus zonas con más impacto,
                    las campañas más efectivas,
                    estos son algunos de los datos que podrás analizar para tomar las mejores decisiones para tu
                    empresa</p>
                </div>
                <ul>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>¿Excel? no es necesario.</h3>
                    <p>Graficas y tendencias en tiempo real, analiza tus datos directamente desde Nova,
                      aun así si eres amante del excel, puedes exportar los datos con un click </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>Un router no es un cliente!</h3>
                    <p>Nova en su arquitectura, centraliza al cliente como persona/empresa,
                      de aquí se desprenden los equipos utilizados para la prestación del servicio.
                      esta característica permite tener una trazabilidad más completa en el historial del cliente.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>Conoce mejor a tus suscriptores</h3>
                    <p>Nova analiza y califica los habitos de pago de tus clientes, si tienes productos
                      de valor agregado y quieres hacer campañas a los clientes con mejor habito de pago, esta herramienta
                      facilita el proceso.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="analyze_image" data-aos="fade-in" data-aos-duration="1000">
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className="moving_animation"
                     src={analize1} alt="image"/>
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className="moving_animation"
                     src={analize2} alt="image"/>
                <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className=""
                     src={analize3} alt="image"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Analyze
