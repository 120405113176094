import React from 'react'
import Header from '../Header/Main'
import ServiceList1 from '../MainServiceList1/ServiceList1'
import WhyChoose from '../MainServiceList1/WhyChoose'
import Customer from '../MainHome/Customer'
import Pricing from '../MainHome/Pricing'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Services List 1"} title={"Home"} subtitle={"Our services"}/>
      </div>
      <ServiceList1 />
      <WhyChoose />
      <Customer />
      <Pricing />
      <Need />
    </>
  )
}

export default Main
