import React from 'react';

function ServiceReview() {
  const reviews = [
    {
      quote: '“ Quick Support & Great Design!',
      name: 'John Doe',
      company: 'Technosoft Pvt Ltd.',
      image: '/assets/images/new/service_coustomer_01.png'
    },
    {
      quote: '“ Bug FREE Coding & Easy Documentation!',
      name: 'Shaily Zill',
      company: 'Envato Corp',
      image: '/assets/images/new/service_coustomer_02.png'
    },
    {
      quote: '“ Excellence Team!',
      name: 'Sophiya Zaa',
      company: 'Mindwebly',
      image: '/assets/images/new/service_coustomer_01.png'
    },
    {
      quote: '“ Appriciate and Recommend Services!!',
      name: 'Tom Cruzz',
      company: 'Smart Webly',
      image: '/assets/images/new/service_coustomer_02.png'
    }
  ];

  return (
    <section className="service_review_section">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500">
          <h2>We work with customers worldwide.</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typese tting indus orem Ipsum has beenthe standard dummy text ever since.</p>
        </div>

        <div className="review_pannel">
          {reviews.map(review => (
            <div className="review_block" key={review.name} data-aos="fade-up" data-aos-duration="1500">
              <p><b>{review.quote}</b> Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took.</p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={review.image} alt="image" />
                  </div>
                  <div className="text">
                    <h3>{review.name}</h3>
                    <span>{review.company}</span>
                  </div>
                </div>
                <div className="rating">
                  <span><i className="icofont-star"></i></span>
                  <span><i className="icofont-star"></i></span>
                  <span><i className="icofont-star"></i></span>
                  <span><i className="icofont-star"></i></span>
                  <span><i className="icofont-star"></i></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServiceReview;
