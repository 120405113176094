import React from 'react'
import { Link } from 'react-router-dom'

const teamMembers = [
  {
    name: "Riana Joe",
    role: "CEO & Co-Founder",
    imgSrc: "/assets/images/new/team_01.png",
    socialMedia: [
      {
        icon: "icofont-facebook",
        link: "#"
      },
      {
        icon: "icofont-twitter",
        link: "#"
      },
      {
        icon: "icofont-instagram",
        link: "#"
      }
    ]
  },
  {
    name: "Shali Jely",
    role: "Co-Founder",
    imgSrc: "/assets/images/new/team_02.png",
    socialMedia: [
      {
        icon: "icofont-facebook",
        link: "#"
      },
      {
        icon: "icofont-twitter",
        link: "#"
      },
      {
        icon: "icofont-instagram",
        link: "#"
      }
    ]
  },
  {
    name: "Mark Merbo",
    role: "Business Developer",
    imgSrc: "/assets/images/new/team_03.png",
    socialMedia: [
      {
        icon: "icofont-facebook",
        link: "#"
      },
      {
        icon: "icofont-twitter",
        link: "#"
      },
      {
        icon: "icofont-instagram",
        link: "#"
      }
    ]
  },
  {
    name: "Cyrus Mistry",
    role: "Marketing & Sales",
    imgSrc: "/assets/images/new/team_04.png",
    socialMedia: [
      {
        icon: "icofont-facebook",
        link: "#"
      },
      {
        icon: "icofont-twitter",
        link: "#"
      },
      {
        icon: "icofont-instagram",
        link: "#"
      }
    ]
  }
];

function OurTeam() {
  return (
    <section className="row_am our_team_section">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500">
          <h2>Our Experience Team</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
        <div className="team_block">
          <div className="row">
            {teamMembers.map((member, index) => (
              <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1500" key={index}>
                <div className="team_inner">
                  <div className="img">
                    <img src={member.imgSrc} alt={member.name} />
                    <div className="social_media">
                      {member.socialMedia.map((platform, index) => (
                        <Link to={platform.link} key={index}><i className={platform.icon}></i></Link>
                      ))}
                    </div>
                  </div>
                  <div className="text">
                    <h3>{member.name}</h3>
                    <span>{member.role}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurTeam
