import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import huawei from '../../assets/images/brands/huawei.png'
import zte from '../../assets/images/brands/zte.png'
import cdata from '../../assets/images/brands/cdata.png'
import fiberhome from '../../assets/images/brands/fiberhome.png'
import nokia from '../../assets/images/brands/nokia.png'
import mikrotik from '../../assets/images/brands/mikrotik.png'
import tplink from '../../assets/images/brands/tplink.png'
import vsol from '../../assets/images/brands/vsol.png'


function Trusted() {

  const settings = {
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 5
      }
    }
  };

  const logos = [
    huawei,
    mikrotik,
    zte,
    fiberhome,
    nokia,
    tplink,
    vsol,
    cdata
  ];

  return (
    <section className="row_am trusted_section">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
          <h2>
            ¿como estás vendiendo tu servicio?
          </h2>
          <p>
            Nova tiene excelentes herramientas comerciales, sin dejar a un lado todas las herramientas para administrar
            tu infraestructura
          </p>
        </div>

        <div className="company_logos" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
          <Swiper {...settings} slidesPerView={8} pagination={{
            clickable: true,
          }} modules={[Pagination]} loop={true} autoplay={true} speed={1500} slidesPerGroup={10} id="company_slider"
                  className="mySwiper">
            {logos.map((logo, index) => (
              <SwiperSlide className="item" key={index}>
                <div className="logo">
                  <img className={"pr-3"} src={logo} alt="logo"/>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Trusted;
