import React from 'react'
import BlogDetails from '../MainBlogDetails/BlogDetails'
import Comment from '../MainBlogDetails/Comment'
import Header from '../Header/Main'
import Bredcom from '../Bredcom/Main'
import Need from '../MainHome/Need'


function Main() {
  return (
    <>
     <div className="inner_page_block">
        <Header />
        <Bredcom same={"Blog Single"} title={"Home"} subtitle={"Blog Single"} />
      </div> 
      <BlogDetails/>
      <Comment/>
      <Need/>
    </>
  )
}

export default Main
