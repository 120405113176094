import React from 'react'
import Header from '../Header/Main'
import BlogList from '../MainBlogList/BlogList'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Blog list classic"} title={"Home"} subtitle={"Blog List"}/>
      </div>
      <BlogList />
      <Need />
    </>
  )
}

export default Main
