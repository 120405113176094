import React from 'react'
import why1 from "../../assets/images/new/why1.png"
import why2 from "../../assets/images/new/why2.png"
import why3 from "../../assets/images/new/why3.png"

function Why() {

  const cards = [
    {
      imageSrc: why1,
      title: 'Analiza tus datos',
      description: 'Mantener tus clientes es tan importante como adquirir nuevos, te ofrecemos herramientas para determinar por que se retiran tus clientes, y cuales son las causas de soporte más frecuente',
      number: '01'
    },
    {
      imageSrc: why2,
      title: 'Gestiona tu infraestructura',
      description: 'No importa si tu red es inalámbrica, fibra, metro o hibrida. Tenemos soporte para las principales marcas del mercado',
      number: '02'
    },
    {
      imageSrc: why3,
      title: 'Trabajas menos, produces más',
      description: 'Nova automatiza las principales tareas como cortes, reconexiones, reporte de pagos, gestión de cobranza, al igual que el soporte básico via whatsapp',
      number: '03'
    }
  ]

  return (
    <>
      <section className="why_section">
        <div className="why_inner">
          <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500">
              <h2>Por qué elegir nuestro software</h2>
              <p>Nova se ha desarrollado por ISP, conociendo las necesidades de los ISP, minimizamos la carga operativa
                y ofrecemos herramientas para que tu empresa se enfoque en lo que más importa, vender.</p>
            </div>
            <div className="row">
              {cards.map((card) => (
                <div className="col-md-4" key={card.title}>
                  <div className="why_cards" data-aos="fade-up" data-aos-duration="1500">
                    <div className="image">
                      <img src={card.imageSrc} alt="image" />
                    </div>
                    <div className="text">
                      <span>{card.number} </span>
                      <h3>{card.title}</h3>
                      <p>{card.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Why
