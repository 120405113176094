import React from 'react'
import Review from '../MainAboutUs/Review'
import AboutUsPage from '../MainAboutUs/AboutUsPage'
import SolutionPage from '../MainAboutUs/SolutionPage'
import OurTeam from '../MainAboutUs/OurTeam'
import Customer from '../MainAboutUs/WhatCust'
import FreeTrial from '../MainHome/FreeTrial'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'
import Header from '../Header/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block h-10">
        <Header/>
        <Bredcom same={"Ficha de inscripción"} title={"Inicio"} subtitle={"Registro"}/></div>
      <iframe className={'mb-5'}
              src="https://docs.google.com/forms/d/e/1FAIpQLSdo2c2z0JTKEiY7sT6yQz2DvEwWj3rSVn7dJbQWnG18XouJoQ/viewform?embedded=true"
              width="100%" height="3580">Cargando…
      </iframe>
    </>
  )
}

export default Main
