import React from 'react'
import { Link } from 'react-router-dom'


function ContactForm() {
	return (
		<>
			<section className="contact_form_section">
				<div className="container">
					<div className="contact_inner">
						<div className="contact_form">
							<div className="section_title">
								<h2>Comunicate con nosotros</h2>
								<p>por favor llena los siguientes datos</p>
							</div>
							<form action="submit">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" placeholder="Nombre" className="form-control" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="email" placeholder="Email" className="form-control" />
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" placeholder="Empresa" className="form-control" />
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<select className="form-control">
												<option value="">País</option>
											</select>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" placeholder="Teléfono" className="form-control" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" placeholder="Sitio web (opcional)" className="form-control" />
										</div>
									</div>
								</div>

								<div className="form-group">
									<textarea className="form-control" placeholder="Escribe tu consulta"></textarea>
								</div>

								<div className="form-group term_check">
									<input type="checkbox" id="term" />
									<label for="term">Acepto recibir comunicaciones, promociones y campañas de Nova</label>
								</div>

								<div className="form-group ">
									<button className="btn btn_main" type="submit">ENVIAR MENSAJE <i className="icofont-arrow-right"></i></button>
								</div>

							</form>

							<div className="form-graphic">
								<img src="/assets/images/new/paperplane.png" alt="image" />
							</div>
						</div>


					</div>
				</div>
			</section>
		</>
	)
}

export default ContactForm
