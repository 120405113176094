import React from 'react'
import Header from '../Header/Main'
import ServiceList2Col from '../MainServiceList2/ServiceList2Col'
import ServiceList from '../MainServiceList2/ServiceList'
import Pricing from '../MainHome/Pricing'
import Customer from '../MainHome/Customer'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Services List 2"} title={"Home"} subtitle={"Our Services"}/>
      </div>
      <ServiceList2Col />
      <ServiceList />
      <Customer />
      <Pricing />
      <Need />
    </>
  )
}

export default Main
