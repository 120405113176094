import React from 'react'
import Header from '../Header/Main'
import ContactList from '../MainContact/ContactList'
import ContactForm from '../MainContact/ContactForm'
import Map from '../MainContact/Map'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <div className="anim_line dark_bg">
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
          <span><img src="/assets/images/anim_line.png" alt="anim_line" /></span>
        </div>
        <Header />
        <Bredcom same={"Contactanos"} title={"Inicio"} subtitle={"Contactanos"} />
      </div>
      <ContactList />
      <ContactForm />
      <Map />
      <Need />
    </>
  )
}

export default Main
