import React from 'react';

const reviews = [
  {
    icon: '/assets/images/new/google.png',
    rating: [
      <span key="1"><i className="icofont-star"></i></span>,
      <span key="2"><i className="icofont-star"></i></span>,
      <span key="3"><i className="icofont-star"></i></span>,
      <span key="4"><i className="icofont-star"></i></span>,
      <span key="5"><i className="icofont-star"></i></span>
    ],
    main_rating: '4.9',
    total_review: '485 Reviews'
  },
  {
    icon: '/assets/images/new/clutch.png',
    rating: [
      <span key="1"><i className="icofont-star"></i></span>,
      <span key="2"><i className="icofont-star"></i></span>,
      <span key="3"><i className="icofont-star"></i></span>,
      <span key="4"><i className="icofont-star"></i></span>,
      <span key="5"><i className="icofont-star"></i></span>
    ],
    main_rating: '4.8',
    total_review: '390 Reviews'
  },
  {
    icon: '/assets/images/new/capterra.png',
    rating: [
      <span key="1"><i className="icofont-star"></i></span>,
      <span key="2"><i className="icofont-star"></i></span>,
      <span key="3"><i className="icofont-star"></i></span>,
      <span key="4"><i className="icofont-star"></i></span>,
      <span key="5"><i className="icofont-star"></i></span>
    ],
    main_rating: '4.6',
    total_review: '276 Reviews'
  }
];

function Review() {
  return (
    <>
      <section className="review_section">
        <div className="container">
          <div className="row">
            {reviews.map((review, index) => (
              <div key={index} className="col-lg-4">
                <div className="review_box" data-aos="fade-up" data-aos-duration="2000">
                  <div className="icon"><img src={review.icon} alt="image" /></div>
                  <div className="rating">{review.rating}</div>
                  <div className="text">
                    <h3>
                      <span className="main_rating">{review.main_rating}</span> out of 5 Based on
                      <span className="total_review">{review.total_review}</span>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Review;
