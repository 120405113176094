import React from 'react'
import { Link } from 'react-router-dom'
import Maintwo from '../Animation/Maintwo'
import logo from '../../assets/images/logos/png/logo-poli-inverted-small.png'

function Main() {
  return (
    <>
      <footer>
        <Maintwo/>

        <div className="top_footer" id="contact">
          <div className="footer_inner">

            <div className="container">

              <div className="row">

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo"> <img src={logo} alt="image" /></div>
                    <p>Nova Gestión total. Nova es desarrollado por Servired LMA Comunicaciones S.A.S </p>
                    <div className="news_letter_block">
                      <form action="submit">
                        <div className="form-group">
                          <input type="email" placeholder="Ingrese su correo electrónico" className="form-control" />
                          <button className="btn"><i className="icofont-paper-plane"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>


                <div className="col-lg-2 col-md-6 col-12">
                  <div className="links">
                    <h3>Enlaces de interés</h3>
                    <ul>
                      <li><Link to="/">Inicio</Link></li>
                      <li><Link to="#">Acerca de Nova</Link></li>
                      <li><Link to="#">Servicios</Link></li>
                      <li><Link to="#">Blog</Link></li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Ayuda y soporte</h3>
                    <ul>
                      <li><Link to="/contact">Contactanos</Link></li>
                      <li><Link to="#">Preguntas frecuentes</Link></li>
                      <li><Link to="#">Trabaja con nosotros</Link></li>
                      <li><Link to="#">Términos y condiciones</Link></li>
                      <li><Link to="#">Política de privacidad</Link></li>
                    </ul>
                  </div>
                </div>


                <div className="col-lg-3 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Contactanos</h3>
                    <ul>
                      <li>
                        <span className="icon">
                          <img src="/assets/images/new/contact_01.png" alt="image" />
                        </span>
                        <div className="text">
                          <p>Cra 15 # 15-13 <br /> Santa Rosa de cabal - Colombia</p>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="/assets/images/new/contact_02.png" alt="image" />
                        </span>
                        <div className="text">
                          <p>Llamanos <Link target={"_blank"} to="https://wa.me/573205005001">+57-320-5005001</Link></p>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="/assets/images/new/contact_03.png" alt="image" />
                        </span>
                        <div className="text">
                          <p>Escríbenos <Link to="mailto:soporte@novaisp.co">soporte@novaisp.co</Link></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="bottom_footer">

          <div className="container">

            <div className="row">
              <div className="col-md-4">
                <p>© Copyrights 2023. Todos los derechos reservados</p>
              </div>
              <div className="col-md-4">
                <ul className="social_media">
                  <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                  <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                  <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                  <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                </ul>
              </div>
              <div className="col-md-4">
                <p className="developer_text">Diseñado por: <Link to="https://servired.co/nova" target="blank">Servired LMA Comunicaciones S.A.S</Link></p>
              </div>
            </div>

          </div>

        </div>

        <div className="go_top" id="Gotop">
          <span><i className="icofont-arrow-up"></i></span>
        </div>
      </footer>
    </>
  )
}

export default Main
