import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import logo from '../../assets/images/logos/png/logo-poli-inverted-small.png'
import Animation from '../Animation/Main'

function Main() {
  const [headerScroll, setheaderScroll] = useState(false);
  const [click, setClick] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [tabclick, setTabclick] = useState(false)
  const path = useLocation().pathname

  useEffect(() => {
    if (path === "/contact") {
      setAnimation(false)
    }
  }, [path])

  useEffect(() => {

    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true)
      } else {
        setheaderScroll(false)
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])


  return (
    <>
      {animation &&
        <Animation/>
      }

      <header className={headerScroll ? "fixed fix_style" : "fixed"}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="image"/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <div className={`toggle-wrap ${click === true ? 'active' : ''}`} onClick={() => {
                  setClick(!click)
                }}>
                  <span className="toggle-bar" onClick={() => setTabclick(!tabclick)}></span>

                </div>
              </span>
            </button>

            <div className={`collapse navbar-collapse ${tabclick === true ? "show" : ""}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">Inicio</Link>
                </li>
                {/*
                <li className="nav-item">
                  <Link className="nav-link" to="/aboutus">Acerca de Nova</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/testimonial">Testimonios</Link>
                </li>

                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#">Servicios</Link>
                  <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                  <div className="sub_menu">
                    <ul>
                      <li><Link to="/servicelist1">Gestión ISP</Link></li>
                      <li><Link to="/servicelist2">Desarrollos a la medida</Link></li>
                      <li><Link to="/servicedetail">Cloud computing </Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/bloglist">Blog</Link>
                </li>
                {/*
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#">Pages </Link>
                  <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                  <div className="sub_menu">
                    <ul>
                      <li><Link to="/pricing">Pricing </Link></li>
                      <li><Link to="/signin">Sign In </Link></li>
                      <li><Link to="/signup">Sign Up </Link></li>
                      <li><Link to="/error">Error 404 </Link></li>
                      <li><Link to="/faq">Faq </Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/pricing">Precios</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Preguntas frecuentes</Link>
                </li>
                */}


                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contactanos</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link dark_btn" to="/signup">PRUEBA GRATUITA <i className="icofont-arrow-right"></i></Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Main
