import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/images/logos/png/logo-poli-inverted-small.png'
import {useFormik} from "formik";
import Axios from "axios"
import ErrorLabel from "./ErrorLabel";

function SignUp() {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [promo, setPromo] = useState()
  const axios = Axios.create({
    baseURL: 'https://cluster.novaisp.co/api/v1/public/company'
  })

  const validate = async () => {
    setLoading(true)
    try {
      const res = await axios.post('/validate', formik.values)
      const {data} = res
      const {promo} = data
      setPromo(promo)
      setStep(2)
    } catch (err) {
      const {errors} = err.response.data
      if (errors) formik.setErrors(errors)
    }

    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {countryId: 1},
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const res = await axios.post('/create', formik.values)
        setStep(3)
      } catch (err) {
        const {errors} = err.response.data
        if (errors) formik.setErrors(errors)
      }

      setLoading(false)
    }
  })


  return (
    <>
      <section className="signup_section">

        <div className="top_part">
          <Link to="/" className="back_btn"><i className="icofont-arrow-left"></i> Volver al inicio </Link>
        </div>

        <div className="profile_block">

          <div className="profile_side">
            <div className="top_side">
              <Link to="#"><img src={logo} alt="image"/></Link>
              <p></p>
              <p>Nova gestión total, la herramienta más completa y potente para la gestión de tu ISP</p>

            </div>
          </div>

          <div className="signup_form">
            <div className="section_title">
              <h2>Descubre las herramientas que Nova tiene para ti</h2>
              <p>prueba nuestro software por 30 días sin costo</p>
            </div>
            <form action="">
              <div className={step === 1 ? 'd-block' : 'd-none'}>
                <div className="form-group">
                  <select
                    className="form-control"
                    placeholder="Pais"
                    name={'countryId'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.countryId}
                  >
                    <option value={1}>Colombia</option>
                  </select>
                  <ErrorLabel formik={formik} field='countryId'/>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Razón Social"
                    name={'businessName'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.businessName}
                  />
                  <ErrorLabel formik={formik} field='businessName'/>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nit"
                    name={'nit'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nit}
                    onKeyDown={(e) => e.keyCode < 30 || /[0-9]/.test(e.key) || e.preventDefault()}
                  />
                  <ErrorLabel formik={formik} field='nit'/>
                </div>
                <div className={'row'}>
                  <div className={'col-8 pr-0'}>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Sub dominio"
                        name={'domain'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.domain}
                        style={{textTransform: 'lowercase'}}
                        onKeyDown={(e) => /[a-zA-Z0-9_]/.test(e.key) || e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className={'col-4 pl-2'}>
                    <div className={'pt-4'}>.novaisp.co</div>
                  </div>
                </div>
                <ErrorLabel formik={formik} field='domain'/>
                <div className={'form-group'}></div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Código Promocional"
                    name={'promoCode'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.promoCode}
                    style={{textTransform: 'lowercase'}}
                    onKeyDown={(e) => /[a-zA-Z0-9_]/.test(e.key) || e.preventDefault()}
                  />
                  <ErrorLabel formik={formik} field='promoCode'/>
                </div>
                <div className="form-group">
                  <button className="btn btn_main" type="button" onClick={validate} disabled={loading}>SIGUIENTE <i
                    className="icofont-arrow-right"></i>
                  </button>
                </div>
              </div>
              <div className={step === 2 ? 'd-block' : 'd-none'}>
                <div className="form-group">
                  {promo ? <>
                    Tu código de descuento:
                    <h3 className={'text-success mb-0'}>{promo.name}</h3>
                    <small className={'font-italic'}>{promo.notes}</small>
                  </> : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre del usuario principal"
                    name={'customerName'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerName}
                  />
                  <ErrorLabel formik={formik} field='customerName'/>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Correo electrónico"
                    name={'email'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <ErrorLabel formik={formik} field='email'/>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Teléfono de contacto"
                    name={'phone'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  <ErrorLabel formik={formik} field='phone'/>
                </div>
                <div className="form-group">
                  <textarea
                    style={{height: '160px'}}
                    className="form-control"
                    placeholder="Comentarios"
                    name={'notes'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                  <ErrorLabel formik={formik} field='notes'/>
                </div>
                {/*
                <div className="form-group">
                  <input
                    autoComplete={'new-password'}
                    type="password"
                    className="form-control"
                    placeholder="Contraseña"
                    name={'password'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <ErrorLabel formik={formik} field='password'/>
                </div>
                <div className="form-group">
                  <input
                    autoComplete={'new-password'}
                    type="password"
                    className="form-control"
                    placeholder="Repetir contraseña"
                    name={'repassword'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.repassword}
                  />
                  <ErrorLabel formik={formik} field='repassword'/>
                </div>
*/}
                <div className="form-group">
                  <button className="btn btn_main" type="button" onClick={formik.submitForm}>CREAR CUENTA <i
                    className="icofont-arrow-right"></i>
                  </button>
                </div>
              </div>
              <div className={step === 3 ? 'd-block' : 'd-none'}>
                <h2 className={'text-center text-success'}>Tu cuenta se ha creado con éxito</h2>
                <p className={'text-center'}> valida tu correo electrónico para continuar
                  <Link to="/"
                        className="btn btn_main"
                        data-aos="fade-right"
                        data-aos-duration={1500}
                  >
                    VOLVER AL INICIO <i className="icofont-arrow-left"/>
                  </Link>
                </p>

              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignUp
