import React from 'react'
import { Link } from 'react-router-dom'

import collaborate1 from "../../assets/images/new/Collaborate-01.png"
import collaborate2 from "../../assets/images/new/Collaborate-02.png"
import collaborateIcon1 from "../../assets/images/new/Collaborate-icon_1.png"
import collaborateIcon2 from "../../assets/images/new/Collaborate-icon_2.png"

function Collaborate() {
  return (
    <>

      <section className="row_am collaborate_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="collaborate_image" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <div className="img_block" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                  <img className="icon_img moving_animation" src={collaborateIcon1} alt="image" />
                  <img src={collaborate1} alt="image" />
                </div>
                <div className="img_block" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                  <img src={collaborate2}  alt="image" />
                  <img className="icon_img moving_animation" src={collaborateIcon2} alt="image" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="collaborate_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <span className="icon"><img src="/assets/images/new/securely.svg" alt="image" /></span>
                <div className="section_title">
                  <h2>¿Que hace Nova por tí?</h2>
                  <p>Impulsado por inteligencia artificial, Nova analiza el estado de tu infrastructura en tiempo real
                   sin impactos significativos sobre tu red, utilizamos protocolos como SNMP, WebSockets, Telnet y SSH
                    para monitorear tus equipos de cabecera, enlaces troncales y sectoriales, si se presenta un failover
                    serás notificado antes que un cliente solicite soporte
                  </p>
                </div>
                <ul>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>ChatBot con IA</h3>
                    <p>Tareas como cambios de la contraseña WIFI, aumentos de velocidad, cambio de canal y ancho de canal WIFI,
                      pueden ser atendidos por nuestro ChatBot para Whatsapp y Telegram, </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <h3>Tu gestión documental en un solo sitio</h3>
                    <p>Firma de contrato, Foto del documento de identidad, evidencias de instalación, evidencias de soporte, fotos de fachadas, recibos de servicios publicos,
                      en fín, cualquier archivo multimedia relacionado con el servicio puede ser almacenado en nuestra nube. </p>
                  </li>
                </ul>
                {/*
                <Link to="/servicedetail" data-aos="fade-up" data-aos-duration="2000" className="btn btn_main">VER MÁS <i
                  className="icofont-arrow-right"></i></Link>
                */}

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Collaborate
