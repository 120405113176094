import React from 'react'
import Review from '../MainAboutUs/Review'
import AboutUsPage from '../MainAboutUs/AboutUsPage'
import SolutionPage from '../MainAboutUs/SolutionPage'
import OurTeam from '../MainAboutUs/OurTeam'
import Customer from '../MainAboutUs/WhatCust'
import FreeTrial from '../MainHome/FreeTrial'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'
import Header from '../Header/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"About us"} title={"Home"} subtitle={"About us"}/>
      </div>
      <Review />
      <AboutUsPage />
      <SolutionPage />
      <OurTeam />
      <Customer />
      <FreeTrial />
      <Need/>
    </>
  )
}

export default Main
