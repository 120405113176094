import React from 'react'
import { Link } from 'react-router-dom'
function Need() {
  return (
    <>
      <section className="need_section" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
        <div className="container">
          <div className="need_block">
            <div className="need_text">
              <div className="section_title">
                <h2>¿Necesitas soporte? contáctanos</h2>
                {/*<p><i className="icofont-clock-time"></i> Soporte 24/7/365</p>*/}
              </div>
            </div>
            <div className="need_action">
              <Link to="tel:3205005001" className="btn"><i className="icofont-phone-circle"></i> +57 320 5005001</Link>
              <Link to="/faq" className="faq_btn">Preguntas frecuentes </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Need
