import React from 'react'

function Main() {
  return (
    <>
        <div className="banner_shapes">
          <div className="container">
            <span><img src="/assets/images/new/plus.svg" alt="image" /></span>
            <span><img src="/assets/images/new/polygon.svg" alt="image" /></span>
            <span><img src="/assets/images/new/round.svg" alt="image" /></span>
          </div>
        </div>
    </>
  )
}

export default Main
